
#logo-header {
    display: block;
    font-size: 3em;
    text-align: center;
    margin-top: 10px; /* Adjust margin as needed */
}

#logo-header img {
    width: 200px;
    height: 200px;
}

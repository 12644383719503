@import '../../common.css';

#sub-quote {
    font-size: 0.8em; /* Smaller font size for the sub-quote */
    background: linear-gradient(45deg, #007BFF, #00FF7F); /* Gradient background */
    -webkit-background-clip: text; /* Clip background to text */
    -webkit-text-fill-color: transparent; /* Make text transparent to show background */
    font-style: italic; /* Italicize the subphrase */
    display: block; /* Ensure it takes up its own line */
    text-align: center; /* Center align the subphrase */
    font-weight: 600; /* Bold font weight for emphasis */
    letter-spacing: 1px; /* Slight letter spacing for readability */
    opacity: 0; /* Start with the text hidden */
    transform: translateY(20px); /* Start with the text slightly below */
    animation: fadeInUp 2s ease forwards; /* Apply the animation */
    margin: 10px 0 20px 0; /* Reduce top margin to bring it closer to #logo-header */
}

@keyframes fadeInUp {
    to {
        opacity: 1; /* Fade in to full opacity */
        transform: translateY(0); /* Slide up to original position */
    }
}

 p.subtitle{
     font-size: 60px;
     text-align: center;
 }
 p.normal {
     font-size: 1.2em;
     text-align: center;
 }

 #info-content {
    font-family: inherit; /* Inherit font from parent */
    font-size: 1.2em;
    color: inherit; /* Inherit text color from parent */
    max-width: 800px; /* Maximum width */
    margin: auto auto; /* Center align with margin */
    line-height: 1.6; /* Line height for readability */
    padding: 0; /* Remove padding */
    border: none; /* Remove border */
    box-shadow: none; /* Remove box shadow */
    background: none; /* Remove background color */
}

#info-content h1, #info-content h2, #info-content h3 {
    color: inherit; /* Inherit heading color from parent */
    margin-bottom: 15px; /* Space below headings */
}

#info-content p {
    margin-bottom: 15px; /* Space below paragraphs */
}

#info-content a {
    color: #007BFF; /* Link color */
    text-decoration: none; /* Remove underline */
}

#info-content a:hover {
    text-decoration: underline; /* Underline on hover */
}


#info-content a:hover {
    text-decoration: underline; /* Underline on hover */
}

@media (max-width: 600px) {
    #info-content {
        padding-left: 10px;
        padding-right: 10px;
    }
}


#link-group {
    display: flex; /* Use flexbox to align items horizontally */
    justify-content: center; /* Center the items horizontally */
    align-items: center; /* Center the items vertically */
    gap: 10em; /* Add space between items */
}